import { Input, Col, Row, Button, Progress, Radio, Space, Image, DatePicker, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons';
import HorizontalDialog from "../components/HorizontalDialog";
import LevelIcon from "../components/LevelIcon";
import { ComponentStates } from '../Activation';
import InfoCard from "../components/InfoCard";
import Dialog from "../components/Dialog";
import CalendarIcon from "../components/CalendarIcon";
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

const SignUpStates = {
    StepOne: 1,
    StepTwo: 2,
    StepThree: 3,
    StepFour: 4,
    StepFive: 5,
    StepSix: 6,
    StepSeven: 7,
    StepEight: 8,
    StepNine: 9,
    StepTen: 10,
}

export default function SignUp({ setComponentState }) {
    const [signUpState, setSignUpState] = useState(SignUpStates.StepOne);
    const [messageApi, contextHolder] = message.useMessage();
    const [signUpSuccess, setSignUpSuccess] = useState(null);
    const [signUpError, setSignUpError] = useState(null);
    const [activationError, setActivationError] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState(null);
    const [conditions, setConditions] = useState(false);
    const signUpUrl = "https://cactusfrance.com/api/app/authentication/signup"
    const activationUrl = "https://cactusfrance.com/api/app/module/autoActivationCode";

    const [isCalOpen, setIsCalOpen] = useState(false);

    const prependZero = (number) => {
        if (number > 9) {
            return number;
        }
        return '0' + number;
    }

    const [signUpData, setSignUpData] = useState({
        email: "",
        name: "",
        newsletter: true,
        rememberMe: true,
        password: "",
        confirmPassword: "",
        dateOfBirth: "",
        deviceToken: "web",
        frenchLevel: 0,
        learningGoals: 0,
        reasonToLearn: 0
    });

    const signUpAndActivate = () => {
        fetch(signUpUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "device": "Web"
            },
            body: JSON.stringify(signUpData)
        }).then(async (response) => {
            if (response.status !== 200) {
                setErrorMessage((await response.json()).message)
                setSignUpError(true);
                return;
            }
            let data = await response.json();
            setToken(data.token);
            console.log(data);

            setSignUpSuccess(true);
        }).catch((response) => {
            setErrorMessage("Une erreur inattendue est survenue, veuillez réessayer plus tard.");
            setSignUpError(true);
        })
    }

    useEffect(() => {
        if (!signUpError && !activationError) {
            return;
        }
        messageApi.error(errorMessage);
        setSignUpError(false);
        setActivationError(false);
        setErrorMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpError, activationError]);

    useEffect(() => {
        if (!token || !signUpSuccess) {
            return;
        }
        fetch(activationUrl, {
            method: "POST",
            mode: "cors",
            referrerPolicy: "unsafe-url",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
                "device": "Web"
            },
            body: JSON.stringify({ link: localStorage.getItem("ModulesLink") })
        }).then(async (response) => {
            if (response.status !== 200) {
                setErrorMessage((await response.json()).message);
                setActivationError(true);
            }
            // let data = await response.json();
            setSignUpState(SignUpStates.StepTen);

        }).catch((response) => {
            setErrorMessage("Une erreur inattendue est survenue, veuillez réessayer plus tard.");
            setActivationError(true);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpSuccess]);


    //#region SubComponents
    // Step One
    const StepOne = (<><div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
    }}>
        <Button onClick={() => { setComponentState(ComponentStates.ChoiceScreen) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
        <Progress percent={25} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
    </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <HorizontalDialog text="Quel est ton niveau de français  ?" />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, frenchLevel: e.target.value })} value={signUpData.frenchLevel} style={{ width: "100%" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Radio.Button className="RadioButton" value={0}> <LevelIcon level={0} />Je viens de commencer à apprendre le français</Radio.Button>
                    <Radio.Button className="RadioButton" value={1}> <LevelIcon level={1} /> Je connais des mots et des phrases</Radio.Button>
                    <Radio.Button className="RadioButton" value={2}> <LevelIcon level={2} /> Je peux avoir une conversation simple</Radio.Button>
                    <Radio.Button className="RadioButton" value={3}> <LevelIcon level={3} /> Je suis au niveau intermédiaire ou supérieur</Radio.Button>
                </Space>
            </Radio.Group>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "10vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepTwo)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div></>)

    // Step Two
    const StepTwo = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => { setSignUpState(SignUpStates.StepOne) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={50} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <HorizontalDialog text="Pourquoi étudies-tu le français ?" />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, reasonToLearn: e.target.value })} value={signUpData.reasonToLearn} style={{ width: "100%" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Radio.Button className="RadioButton" value={0}>😁 Juste pour le fun</Radio.Button>
                    <Radio.Button className="RadioButton" value={1}>💼 Améliorer ma carrière</Radio.Button>
                    <Radio.Button className="RadioButton" value={2}>🎓 Améliorer mon apprentissage scolaire</Radio.Button>
                    <Radio.Button className="RadioButton" value={3}>✈️ Préparation des vacances</Radio.Button>
                    <Radio.Button className="RadioButton" value={4}>🧩 D'autres raison</Radio.Button>
                </Space>
            </Radio.Group>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "10vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepThree)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Three
    const StepThree = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => { setSignUpState(SignUpStates.StepTwo) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={75} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <HorizontalDialog text="Voici ce que tu peux réaliser avec cactus" />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <InfoCard style={{ width: "100%" }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "10vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepFour)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Four
    const StepFour = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => { setSignUpState(SignUpStates.StepThree) }} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={100} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <HorizontalDialog text="Quel est ton objectif quotidien ?" />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <Radio.Group onChange={(e) => setSignUpData({ ...signUpData, learningGoals: e.target.value })} value={signUpData.learningGoals} style={{ width: "100%" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Radio.Button className="RadioButton" value={0}>5 mins / jour</Radio.Button>
                    <Radio.Button className="RadioButton" value={1}>10 mins / jour</Radio.Button>
                    <Radio.Button className="RadioButton" value={2}>15 mins / jour</Radio.Button>
                    <Radio.Button className="RadioButton" value={3}>30 mins / jour</Radio.Button>
                    <Radio.Button className="RadioButton" value={4}>60 mins / jour</Radio.Button>
                </Space>
            </Radio.Group>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "10vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepFive)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Five
    const StepFive = (<>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8vh"
        }}>
            <Dialog text="Hourra !!" />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Image
                src="/activation/Lilly.png"
                preview={false} />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#616161" }}>Crée un profil maintenant pour pouvoir enregistrer ta progression et te connecter avec tes amis.</p>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15vh"
        }}>
            <Button className="customButton" onClick={() => { setSignUpState(SignUpStates.StepSix); console.log(signUpData) }} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px" }}>CONTINUER</Button>
        </Row>
    </>)

    // Step Six
    const StepSix = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={25} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Quel est ton nom? 🧑 👩</p>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Nom et prénom</p>
            <Input rootClassName="inputField" value={signUpData.name} onChange={(e) => setSignUpData({ ...signUpData, name: e.target.value })} bordered={false}></Input>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "45vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepSeven)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Seven
    const StepSeven = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => setSignUpState(SignUpStates.StepSix)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={50} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Quel âge as-tu? 🎂</p>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Date de naissance </p>
            {/*// TODO: Sanitize this input*/}
            <Input
                rootClassName="inputField"
                value={signUpData.dateOfBirth}
                // onChange={(e) => { setSignUpData({ ...signUpData, dateOfBirth: e.target.value }) }}
                suffix={
                    <Button className="calendarButton" onClick={() => setIsCalOpen(!isCalOpen)} icon={<CalendarIcon />}></Button>
                }
                bordered={false}
                placeholder="aaaa-mm-jj"
            />
            <DatePicker locale={locale} onChange={(e) => setSignUpData({ ...signUpData, dateOfBirth: `${e.year()}-${prependZero(e.month() + 1)}-${prependZero(e.date())}` })} placeholder="" open={isCalOpen} style={{ opacity: 0, border: "none", textIndent: "-9999px" }} aria-hidden="true" />
        </Row>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "45vh"
        }}>
            <Button className="customButton" onClick={() => setSignUpState(SignUpStates.StepEight)} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Eight
    const StepEight = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => setSignUpState(SignUpStates.StepSeven)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={75} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Quelle est ton adresse email? ✉️</p>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>E-mail </p>
            <Input rootClassName="inputField" value={signUpData.email} onChange={(e) => { setSignUpData({ ...signUpData, email: e.target.value }) }} bordered={false} placeholder="johndoe@gmail.com"></Input>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "2.5vh"
        }}>
            <Checkbox checked={conditions} onChange={(e) => setConditions(e.target.checked) } >Accepter nos conditions d’utilisation générales.</Checkbox>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "2.5vh"
        }}>
            <Checkbox checked={signUpData.newsletter} onChange={(e) => setSignUpData({...signUpData, newsletter: e.target.checked})}>Accepter de recevoir notre newsletter.</Checkbox>
        </Row>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "30vh"
        }}>
            <Button className="customButton" disabled={!conditions} onClick={() => setSignUpState(SignUpStates.StepNine)} style={{ color: "#fff", backgroundColor: conditions ? "#39B13D" : "#39B13D81", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Nine
    const StepNine = (<>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        }}>
            <Button onClick={() => setSignUpState(SignUpStates.StepEight)} style={{ margin: 0, marginRight: "1vw", border: "none" }} icon={<ArrowLeftOutlined />} />
            <Progress percent={100} showInfo={false} strokeColor="#42D83F" style={{ lineHeight: 0, margin: 0 }} />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "5vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "28px", color: "#212121" }}>Crée un mot de passe 🔐</p>
        </div>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Mot de passe </p>
            <Input.Password
                rootClassName="inputField"
                value={signUpData.password}
                onChange={(e) => { setSignUpData({ ...signUpData, password: e.target.value }) }}
                bordered={false}
                rules={[{
                    required: true,
                    message: 'Veuillez insérer votre mot de passe!'
                }]}
            ></Input.Password>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "18px", color: "#212121" }}>Confirme ton mot de passe </p>
            <Input.Password
                rootClassName="inputField"
                value={signUpData.confirmPassword}
                onChange={(e) => { setSignUpData({ ...signUpData, confirmPassword: e.target.value }) }}
                bordered={false}
                rules={[{
                    required: true,
                    message: 'Veuillez insérer votre mot de passe!'
                }]}
            ></Input.Password>
        </Row>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            marginTop: "34vh"
        }}>
            <Button className="customButton" onClick={signUpAndActivate} style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px", width: "100%" }}>CONTINUER</Button>
        </div>
    </>)

    // Step Ten
    const StepTen = (<>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8vh"
        }}>
            <Dialog text="Hourra !!" />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Image
                src="/activation/Lilly.png"
                preview={false} />
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "40px", color: "#424242" }}>Bienvenue 👋</p>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0vh"
        }}>
            <p style={{ lineHeight: "normal", fontWeight: "700", fontSize: "20px", color: "#616161" }}>Ton profil a été créé avec succès, et tu as débloqué un abonnement !</p>
        </Row>
        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10vh"
        }}>
            <a href="https://app.cactusfrance.com/">
                <Button className="customButton" style={{ color: "#fff", backgroundColor: "#39B13D", borderRadius: "100px" }}>CONTINUER VERS L'APPLICATION WEB</Button>
            </a>
        </Row>
    </>)
    //#endregion SubComponents

    let StepToRender;
    switch (signUpState) {
        case SignUpStates.StepOne:
            StepToRender = StepOne;
            break;
        case SignUpStates.StepTwo:
            StepToRender = StepTwo;
            break;
        case SignUpStates.StepThree:
            StepToRender = StepThree;
            break;
        case SignUpStates.StepFour:
            StepToRender = StepFour;
            break;
        case SignUpStates.StepFive:
            StepToRender = StepFive;
            break;
        case SignUpStates.StepSix:
            StepToRender = StepSix;
            break;
        case SignUpStates.StepSeven:
            StepToRender = StepSeven;
            break;
        case SignUpStates.StepEight:
            StepToRender = StepEight;
            break;
        case SignUpStates.StepNine:
            StepToRender = StepNine;
            break;
        case SignUpStates.StepTen:
            StepToRender = StepTen;
            break;
        default:
            StepToRender = StepOne;
            break;
    }

    return (
        <Col style={{
            width: "30vw",
            position: "absolute",
            top: "3vh"
        }}>
            {contextHolder}
            {StepToRender}
        </Col>
    )
}